.login {
  background-color: #08415c !important;
  font-weight: 600;
  color: #fff !important;
  line-height: 1.5;
  padding: 5px 15px;
  border-color: #08415c !important;
  width: 100%;
  margin: 0 auto
}

.options {
  margin-bottom: 2rem;
}