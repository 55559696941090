.divider {
  border-width: 95%;
  border-color: #dededf;
}
.divider.bottom {
  border-bottom: 1px solid #dededf;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.btn {
  padding: 0.78571429em 1.5em 0.78571429em !important;
  line-height: 1em !important;
  vertical-align: baseline;
}

.header {
  vertical-align: baseline !important;
}
