.devise {
  max-width: 410px;
  margin: 0 auto;
  padding: 30px 0 20px;
  margin-top: 40px;
  caret-color: transparent;
}
.devise a {
  color: #08415c;
}

.devise a:hover {
  color: #60c9f1;
}

.register-remarks {
  text-align: center;
  font-size: 1.1rem;
  color: #606a7b;
  margin-bottom: 2rem;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.heavy {
  font-weight: 600;
}

.title {
  color: #052839;
}
