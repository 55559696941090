#form-wrapper {
  /* max-width: 100vw;
  width: max-content; */
}

.form {
  width: 100%;
}

.btn {
  padding: 0.78571429em 1.5em 0.78571429em !important;
  line-height: 1em !important;
  vertical-align: baseline;
  margin: 0 0.25em 0 0;
}
