/* Webfont: Lato-Black */
@font-face {
  font-family: "Lato-Black";

  src: url("./assets/fonts/Lato-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */
@font-face {
  font-family: "Lato-Bold";

  src: url("./assets/fonts/Lato-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Hairline */
@font-face {
  font-family: "Lato-Hairline";

  src: url("./assets/fonts/Lato-Hairline.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Heavy */
@font-face {
  font-family: "Lato-Heavy";

  src: url("./assets/fonts/Lato-Heavy.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */
@font-face {
  font-family: "Lato-Light";

  src: url("./assets/fonts/Lato-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */
@font-face {
  font-family: "Lato-Medium";

  src: url("./assets/fonts/Lato-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */
@font-face {
  font-family: "Lato";

  src: url("./assets/fonts/Lato-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */
@font-face {
  font-family: "Lato-Semibold";

  src: url("./assets/fonts/Lato-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Thin */
@font-face {
  font-family: "Lato-Thin";

  src: url("./assets/fonts/Lato-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
